@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.bio-container {
  margin-top: 5%;
  display: block;
  position: relative;
  z-index: 600;
}
.bio-container .bio-wrapper {
  display: block;
}
.bio-container .bio-wrapper .s-wrap-1 img {
  width: 18vw;
  margin-bottom: 16px;
}
.bio-container .bio-wrapper .s-wrap-1 h1::before {
  display: block;
  margin: 0 auto;
  content: " ";
  background-image: url(../../assets//icons/barber_deco.svg);
  background-repeat: no-repeat;
  background-size: 129px 13px;
  height: 13px;
  width: 129px;
  transform: rotate(180deg);
  margin-bottom: 16px;
}
.bio-container .bio-wrapper .s-wrap-1 h1 {
  font-size: 5vw;
  font-weight: 700;
  font-family: "Spectral", serif;
  text-transform: uppercase;
}
.bio-container .bio-wrapper .s-wrap-1 h1::after {
  display: block;
  margin: 0 auto;
  content: " ";
  background-image: url(../../assets//icons/barber_deco.svg);
  background-repeat: no-repeat;
  background-size: 129px 13px;
  height: 13px;
  width: 129px;
  margin-top: 16px;
  margin-bottom: 40px;
}
.bio-container .bio-wrapper .s-wrap-2 p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 19px;
  letter-spacing: 0.01em;
  line-height: 1.7em;
  text-align: center;
  color: #020303;
  display: block;
  margin: 1em;
}
.bio-container .bio-wrapper .s-wrap-2 button {
  padding: 1rem;
  margin: 1em 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.1em;
  color: #020303;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid black;
  cursor: pointer;
}
.bio-container .brush {
  display: hidden;
}

@media screen and (min-width: 768px) {
  .bio-container {
    margin-top: 10vh;
  }
  .bio-container .bio-wrapper .s-wrap-1 img {
    width: 8vw;
  }
  .bio-container .bio-wrapper .s-wrap-1 h1 {
    font-size: 2.5vw;
  }
  .bio-container .bio-wrapper .s-wrap-2 {
    padding: 0 25vw;
    margin-bottom: 1rem;
  }
  .bio-container .brush {
    display: unset;
    width: 400px;
    height: 600px;
    background: url(../../assets/barber_brush.png);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 2vh;
    left: 1px;
    z-index: -1;
  }
}/*# sourceMappingURL=bio.css.map */