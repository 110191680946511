@import '../../styles/globals';

.hero-container{
    background-image: url(../../assets/barber-hero-cli.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 32vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .hero-title {
        font-size: 8vw;
        font-weight: 800;
        align-items: center;
        
        /** TEXT GRADIENT */ 
        @include golden-text
    }
    
    .hero-border{
        @include tear-border(18vh);
    }
}


@media screen and (min-width: 768px) {
    .hero-container{
        height: 100vh;

        .hero-border{
            @include tear-border(90vh);
        }
    }
}