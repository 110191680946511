@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.reviews-container {
  height: auto;
  margin: 5vh 0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.reviews-container .reviews-title h1 {
  font-family: "spectral-sc", sans-serif;
  font-size: 5rem;
}
.reviews-container .reviews-title p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.5vw;
  color: black;
}
.reviews-container .reviews-title h2 {
  font-family: "spectral", sans-serif;
  word-spacing: 1vw;
  letter-spacing: 0.2vw;
  font-size: 32px;
}
.reviews-container .reviews-title h2::after {
  display: block;
  margin: 0 auto;
  content: " ";
  background-image: url(../../assets//icons/barber_deco.svg);
  background-repeat: no-repeat;
  background-size: 129px 13px;
  height: 13px;
  width: 129px;
  margin-top: 16px;
  margin-bottom: 40px;
}
.reviews-container .review {
  opacity: 0;
  animation: fadeInOut 6s infinite;
  margin: 5vh 0;
  width: 100%;
}
.reviews-container .review p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: italic;
  font-size: 21px;
  margin: 0 5rem;
  text-align: center;
}
.reviews-container .reviews-border {
  background-color: black;
}
.reviews-container .razor {
  display: none;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  25%, 75% {
    opacity: 1;
  }
}
@media screen and (min-width: 765px) {
  .reviews-container .reviews-title p {
    font-size: 16px;
  }
  .reviews-container .review p {
    font-size: 32px;
  }
  .reviews-container .razor {
    position: absolute;
    right: 10%;
    z-index: 700;
    transform: translate(0%, -20%);
    display: unset;
    padding: 15%;
    background: url(../../assets/barber_razor.png);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: contain;
  }
}/*# sourceMappingURL=reviews.css.map */