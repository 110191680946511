@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

.navigation-container{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 40px;
    background-color: transparent;
    z-index: 1000;
}

.hamburger {
    cursor: pointer;
    font-size: 35px;
    margin-right: 15px;
    color: white;
    position: fixed; /* Fix position */
    top: 10px;
    right: 10px;
    z-index: 1001; /* Above the menu */
  }

  .logo{
    @include golden-text;
    font-size: 32px;
    font-weight: 900;
    
    img{
      width: 50px;
    }
  }
  
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
  }
  
  .menu li {
    margin: 20px 0;
    cursor: pointer;
  }
  

@media (min-width: 768px) {
    .menu {
        width: 300px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        left: unset;
        right:0;
        transition: transform 0.5s ease;
      }
    
      .navigation-menu.open .menu {
        transform: translateX(0);
      }
    
}