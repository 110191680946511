@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
@keyframes shadowFloat {
  0%, 100% {
    transform: translateX(-50%) scaleX(1) scaleY(0.1);
    opacity: 1;
  }
  50% {
    transform: translateX(-50%) scaleX(1.2) scaleY(0.15);
    opacity: 0.5;
  }
}
.contact-container {
  position: relative;
  z-index: 9999999;
  margin-top: 12rem;
}
.contact-container .contact-container {
  display: flex;
  flex-direction: row;
  padding: 3.125rem 8.7rem 3.125rem 8.7rem;
}
.contact-container .contact-left {
  max-width: 31.875rem;
  width: 100%;
}
.contact-container .contact-left h1 {
  font-size: 3.2rem;
  text-align: left;
  margin-right: 8rem;
  font-weight: 700;
  padding: 0.3125rem 0;
  line-height: 89%;
  text-transform: uppercase;
}
.contact-container .contact-left-img {
  position: relative;
  display: inline-block;
  width: 100%;
}
.contact-container .contact-left-img img {
  display: block;
  width: 100%;
  height: auto;
  -o-object-fit: contain;
  object-fit: contain;
  margin-bottom: 0.875rem;
  margin-top: -1.25rem;
  position: relative;
  animation: float 3s ease-in-out infinite;
  transition: top 0.2s ease;
  z-index: 2;
}
.contact-container .contact-left-img::after {
  display: block;
  content: "";
  width: 18vw;
  height: 5.5vh;
  margin-left: 50%;
  border-radius: 100%;
  background-color: #d3d3d3;
  filter: blur(20.5px);
  animation: shadowFloat 3s ease-in-out infinite;
  z-index: 1;
}
.contact-container .contact-right {
  display: flex;
  flex-direction: column;
  max-width: 35.625rem;
  width: 100%;
}
.contact-container .contact-right-form-title {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  padding: 2rem 0rem 1rem 8rem;
}
.contact-container form {
  display: block;
  padding: 0rem 0rem 0rem 8.8rem; /* 5rem 0rem 0rem 8.8rem or 80px 0 0 20vh */
}
.contact-container input,
.contact-container textarea {
  width: 100%;
  border: 5px solid transparent;
  border-radius: 3px;
  resize: none;
  padding: 0 5px;
  box-sizing: border-box;
  font-family: "Open Sans" sans-serif;
  font-size: 16px;
  color: #000000;
  background-color: #F0F0F0;
  margin: 15px 0 0 0;
}
.contact-container input[type=text],
.contact-container input[type=email],
.contact-container textarea {
  padding: 2px 8px;
  height: 40px;
  width: 100%;
}
.contact-container input:focus,
.contact-container textarea:focus {
  outline: none;
}
.contact-container .form-input {
  margin: 5px 0;
}
.contact-container .form-select {
  width: 100%;
  margin: 15px 0 0 0;
  padding: 15px 0 15px 8px;
}
.contact-container .form-textarea {
  margin: 15px 0 0 0;
  padding: 8px;
  width: 100%;
  height: 100px;
}
.contact-container input[type=submit] {
  width: 100%;
  height: 50px;
  background-color: #000000;
  border: 0;
  color: #ffffff;
  border: 5px solid transparent;
  border-radius: 8px;
  margin: 10px 0 0 0;
  font-family: "Open Sans" sans-serif;
  font-size: 22px;
  text-transform: uppercase;
}
.contact-container input[type=submit]:hover {
  background-color: rgba(125, 17, 17, 0.2196078431);
  color: #000000;
}

/* Large screen view */
@media screen and (min-width: 1700px) {
  .contact-container {
    align-items: center;
  }
  .contact-left {
    margin-right: 32%;
  }
}
/* Mobile view */
@media screen and (max-width: 950px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .contact-left {
    padding: 5rem 0;
    width: 100%;
  }
  .contact-left h1 {
    font-size: 2rem; /* Smaller font size */
    text-align: center;
    margin-right: 4rem;
  }
  .contact-left-img img {
    width: 90%;
  }
  .contact-right-form-title {
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    padding: 2rem 0rem 1rem 0rem;
  }
  form {
    padding: 0rem 0rem 0rem 8.8rem;
    margin-bottom: 10rem;
  }
  .contact-right form {
    padding: 1rem 1rem;
  }
}/*# sourceMappingURL=contact.css.map */