@mixin box-style($h: content-aware, $w:83vw, $r: 15px) {
    background-color: #fff;
    border: 2px solid #fff;
    height: $h;
    width: $w;
    border-radius: $r;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

@mixin icon-size($h:50px) {
    height: $h;
}

.links-container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F5F5F5;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    margin: 0 0 12% 0;
    z-index: 900000000;
    
    
    .links-profile {
        margin: 18% 0;
        @include box-style;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .links-photos{
            display: flex;
            flex-direction: column;
            align-items: center;

            .links-cover{
                @include box-style(18vh, 78vw);
                margin: 25px;
                border: none;
                box-shadow: none;
                object-fit: cover;
                object-position: center;
            }

            .profile-photo{
                border-radius: 50%;
                height: 100px;
                margin-top: -80px;
            }
        }
        
        .links-title {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin: 15px 0;
            p {
                color: #989696;
            }

        }

        .links-share-btn {
            margin: 8px;
            padding: 8px;
            text-align: center;
            border: 2px solid #3D88FA;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            color: #3D88FA;
            font-weight: 600 ;
            cursor: pointer;

            img {
                @include icon-size(30px);
            }
        }
    }

    .links-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        margin: 0% 0 18% 0;
        cursor: pointer;


        .link {
            @include box-style(8vh, 83vw);
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            
            .link-icon-text {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
                color: #000;
                margin: 0 15px;
                gap: 15px;
                
                .fab {
                    color: #000;
                    font-size: 2em;
                }
            }
            
            .go-to-icn {
                @include icon-size;
            }
        }
    }
}


@media (min-width:720px) {
    .links-container {
        margin-bottom: 40%;
        // padding: -25em;
    }
    .links-profile{
        height: 150vh;
    }

    .links-buttons {
        margin: 0 0 40% 0;
    }
}