.links-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #F5F5F5;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  margin: 0 0 12% 0;
  z-index: 900000000;
}
.links-container .links-profile {
  margin: 18% 0;
  background-color: #fff;
  border: 2px solid #fff;
  height: content-aware;
  width: 83vw;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.links-container .links-profile .links-photos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.links-container .links-profile .links-photos .links-cover {
  background-color: #fff;
  border: 2px solid #fff;
  height: 18vh;
  width: 78vw;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 25px;
  border: none;
  box-shadow: none;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.links-container .links-profile .links-photos .profile-photo {
  border-radius: 50%;
  height: 100px;
  margin-top: -80px;
}
.links-container .links-profile .links-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 15px 0;
}
.links-container .links-profile .links-title p {
  color: #989696;
}
.links-container .links-profile .links-share-btn {
  margin: 8px;
  padding: 8px;
  text-align: center;
  border: 2px solid #3D88FA;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: #3D88FA;
  font-weight: 600;
  cursor: pointer;
}
.links-container .links-profile .links-share-btn img {
  height: 30px;
}
.links-container .links-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin: 0% 0 18% 0;
  cursor: pointer;
}
.links-container .links-buttons .link {
  background-color: #fff;
  border: 2px solid #fff;
  height: 8vh;
  width: 83vw;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.links-container .links-buttons .link .link-icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: #000;
  margin: 0 15px;
  gap: 15px;
}
.links-container .links-buttons .link .link-icon-text .fab {
  color: #000;
  font-size: 2em;
}
.links-container .links-buttons .link .go-to-icn {
  height: 50px;
}

@media (min-width: 720px) {
  .links-container {
    margin-bottom: 40%;
  }
  .links-profile {
    height: 150vh;
  }
  .links-buttons {
    margin: 0 0 40% 0;
  }
}/*# sourceMappingURL=LinksPage.css.map */