@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.pricing-container {
  position: relative;
  height: auto;
  text-align: center;
  z-index: 1;
}
.pricing-container .border {
  background: url(../../assets/barber_border.png);
  background-position: center;
  background-size: cover;
  position: absolute;
  top: unset;
  width: 100%;
  height: 142px;
  z-index: 500;
}
.pricing-container .main-title {
  margin-bottom: 5rem;
}
.pricing-container .main-title p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.5vw;
  color: black;
  text-transform: uppercase;
}
.pricing-container .main-title h2 {
  font-size: 5vw;
  font-weight: 700;
  font-family: "Spectral", serif;
  text-transform: uppercase;
  font-size: 2em;
}
.pricing-container .services-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 90px;
  padding: 20px;
}
.pricing-container .services-grid .service-title {
  margin: 0 0 15px 0;
}
.pricing-container .services-grid .service-category {
  margin-bottom: 30px;
}
.pricing-container .services-grid .service-category .service-item {
  text-align: left;
  margin-bottom: 15px;
}
.pricing-container .services-grid .service-category .service-item .service-name-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing-container .services-grid .service-category .service-item .service-name-price h3 {
  color: #333;
  margin-right: 10px; /* Add space before hyphen line */
  margin-bottom: 15px;
  flex: 1; /* Allows the name to take up available space */
}
.pricing-container .services-grid .service-category .service-item .service-name-price .price-hyphen {
  flex-grow: 1; /* Makes the hyphen line grow to fill space */
  background: linear-gradient(to right, transparent, #333, transparent);
  height: 1px;
  margin: 0 10px;
}
.pricing-container .services-grid .service-category .service-item .service-name-price .price {
  color: #F8D379;
  background-image: -webkit-radial-gradient(circle, rgb(253, 188, 23) 41%, #D3A73E 67%, #C48F38 87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  white-space: nowrap;
}
.pricing-container .more-services-btn {
  padding: 1rem;
  margin: 1em 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.1em;
  color: #020303;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .pricing-container .main-title {
    margin-bottom: 5rem;
  }
  .pricing-container .main-title p {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0.5vw;
    color: black;
    font-size: 1em;
    text-transform: uppercase;
  }
  .pricing-container .main-title h2 {
    font-size: 5vw;
    font-weight: 700;
    font-family: "Spectral", serif;
    text-transform: uppercase;
    text-transform: none;
  }
  .pricing-container .services-grid {
    grid-template-columns: repeat(3, 1fr);
    margin: auto 20%;
  }
  .pricing-container .services-grid .service-category {
    margin-bottom: 0;
  }
  .pricing-container .services-grid .service-category .service-item {
    max-width: 100%;
  }
}/*# sourceMappingURL=pricing.css.map */