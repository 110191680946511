@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

.bio-container{
    margin-top: 5%;
    display: block;
    position:relative;
    z-index: 600;

    .bio-wrapper {
        display: block;
        .s-wrap-1 {
            img {
                width: 18vw;
                margin-bottom: 16px;
            }

            h1::before{
                @include decor-before;
            }

            h1{
                @include title-xl;
            }

            h1::after{
                @include decor-after;
            }
        }

        .s-wrap-2{
            p{
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-size: 19px;
                letter-spacing: 0.01em;
                line-height: 1.7em;
                text-align: center;
                color: #020303;
                display: block;
                margin: 1em;
            }

            button {
                @include button-style
            }
        }
    }

    .brush{
        display: hidden;
    }



}

@media screen and (min-width: 768px) {
    .bio-container {
        margin-top: 10vh;
        .bio-wrapper {
            .s-wrap-1 {
                img {
                    width: 8vw;
                }

                h1{
                    font-size: 2.5vw;
                }
            }
            
            .s-wrap-2 {
                padding: 0 25vw;
                margin-bottom: 1rem;
            }
        }

        .brush{
            display: unset;
            width: 400px;
            height: 600px;
            background: url(../../assets/barber_brush.png);
            background-position: center 0;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 2vh;
            left: 1px;
            z-index: -1;
        }

    }
}
