@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';


.pricing-container {
    position: relative;
    height: auto;
    text-align: center;
    z-index: 1;

    .border{
        @include tear-border(unset);
        // bottom: 0;
        // background-color: black;
    }

    .main-title{
        margin-bottom: 5rem;

        p{
            @include pre-title;
            text-transform: uppercase;
        }

        h2{
            @include title-xl;
            font-size: 2em;
        }
    }

    .services-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 90px;
        padding: 20px;


        .service-title{
            margin: 0 0 15px 0;
        }

        .service-category {
            margin-bottom: 30px;

            .service-item {
                text-align: left;
                margin-bottom: 15px;

                .service-name-price {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h3 {
                        color: #333;
                        margin-right: 10px; /* Add space before hyphen line */
                        margin-bottom: 15px;
                        flex: 1; /* Allows the name to take up available space */
                    }

                    .price-hyphen {
                        flex-grow: 1; /* Makes the hyphen line grow to fill space */
                        background: linear-gradient(to right, transparent, #333, transparent);
                        height: 1px;
                        margin: 0 10px;
                    }

                    .price {
                        @include golden-text;
                        font-weight: bolder;
                        white-space: nowrap;
                    }
                }


            }


        }
    }



    .more-services-btn {
        @include button-style;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .pricing-container {

        .main-title{
            margin-bottom: 5rem;
    
            p{
                @include pre-title;
                font-size: 1em;
                text-transform: uppercase;
            }
            
            h2{
                @include title-xl;
                text-transform: none;

            }
        }

        .services-grid {
            grid-template-columns: repeat(3, 1fr);
            // gap: 20px;
            // width: 80%;
            margin: auto 20%;

            .service-category {
                margin-bottom: 0;

                .service-item {
                    max-width: 100%;
                }
            }
        }
    }


}