*{
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  margin:0;
  padding:0;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

body{
  background: url('assets/barber_pattern.jpeg');
  min-height: 100vh;
  font-size: 16px;
}