@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

.gallery-container {
    position: relative;
    background: url('../../assets/barber_chair.png');
    background-size: cover;
    height: 100vh;
    margin-bottom: 15px;
    overflow: hidden;

    .gallery-title {
        margin-top: 80px;
        position: relative;
        z-index: 9999;

        p{
            @include pre-title;
            color:white;
            text-transform: uppercase;
        }
        h2{
            @include title-xl;
            @include golden-text;
        }

    }

    .border-1{
        @include tear-border-reverse(90vh);
    }

    .border-2{
        @include tear-border(85vh);
    }

    .gallery-item {
        margin: 10px;
        max-height: 300px;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        position: relative;
        transition: transform 0.5s ease;
        z-index: 2;
    }
    
    .item-1 { top: 10px; left: 10px; margin: 50px; }
    .item-2 { top: -90px; left: -80px; margin: 80px;  }
    .item-3 { top: -450px; left: 120px; margin: 50px; }
    .item-4 { top: -550px; left: 120px; margin: 80px; }
    .item-5 { top: -790px; right: 80px; margin: 90px; }

    img {
        max-height: 100%;
        max-width: 100%;
        display: block;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    }
    
    
    
    
}

.gallery-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
    z-index: 1;
}
.gallery-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/barber_chair.png');
    background-size: cover;
    filter: blur(5px); /* Blur effect */
    z-index: -1; /* Ensuring it stays behind the content */
  }
  

@media (min-width: 768px) {
    .gallery-container{
        .border-2{
            @include tear-border(90vh);
        }

        .gallery-title {
            p{
                font-size: 15px;
                font-weight: 600;
             }
        }

        .gallery-item {
            // max-height: 5px;
            // max-width: 500px;
            padding: 5rem 40rem;
            // border: 1rem solid red;
        }
        .item-1 { top: 10px; left: -350px; z-index: 1; margin: 8px; }
        .item-2 { top: -200px; left: -5rem; z-index: 2;}
        .item-3 { top: -450px; left: 120px; z-index: 4; margin: 40px; }
        .item-4 { top: -60rem; left: 12rem; z-index: 3;}
        .item-5 { top: -1400px; left: 460px; z-index: 6; margin: 15px; }

    }
  
}