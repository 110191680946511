@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.gallery-container {
  position: relative;
  background: url("../../assets/barber_chair.png");
  background-size: cover;
  height: 100vh;
  margin-bottom: 15px;
  overflow: hidden;
}
.gallery-container .gallery-title {
  margin-top: 80px;
  position: relative;
  z-index: 9999;
}
.gallery-container .gallery-title p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.5vw;
  color: black;
  color: white;
  text-transform: uppercase;
}
.gallery-container .gallery-title h2 {
  font-size: 5vw;
  font-weight: 700;
  font-family: "Spectral", serif;
  text-transform: uppercase;
  color: #F8D379;
  background-image: -webkit-radial-gradient(circle, rgb(253, 188, 23) 41%, #D3A73E 67%, #C48F38 87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gallery-container .border-1 {
  background: url(../../assets/barber_border.png);
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 90vh;
  width: 100%;
  height: 142px;
  z-index: 500;
  transform: rotate(180deg);
  top: 0;
}
.gallery-container .border-2 {
  background: url(../../assets/barber_border.png);
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 85vh;
  width: 100%;
  height: 142px;
  z-index: 500;
}
.gallery-container .gallery-item {
  margin: 10px;
  max-height: 300px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  transition: transform 0.5s ease;
  z-index: 2;
}
.gallery-container .item-1 {
  top: 10px;
  left: 10px;
  margin: 50px;
}
.gallery-container .item-2 {
  top: -90px;
  left: -80px;
  margin: 80px;
}
.gallery-container .item-3 {
  top: -450px;
  left: 120px;
  margin: 50px;
}
.gallery-container .item-4 {
  top: -550px;
  left: 120px;
  margin: 80px;
}
.gallery-container .item-5 {
  top: -790px;
  right: 80px;
  margin: 90px;
}
.gallery-container img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
  z-index: 1;
}

.gallery-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../assets/barber_chair.png");
  background-size: cover;
  filter: blur(5px); /* Blur effect */
  z-index: -1; /* Ensuring it stays behind the content */
}

@media (min-width: 768px) {
  .gallery-container .border-2 {
    background: url(../../assets/barber_border.png);
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 90vh;
    width: 100%;
    height: 142px;
    z-index: 500;
  }
  .gallery-container .gallery-title p {
    font-size: 15px;
    font-weight: 600;
  }
  .gallery-container .gallery-item {
    padding: 5rem 40rem;
  }
  .gallery-container .item-1 {
    top: 10px;
    left: -350px;
    z-index: 1;
    margin: 8px;
  }
  .gallery-container .item-2 {
    top: -200px;
    left: -5rem;
    z-index: 2;
  }
  .gallery-container .item-3 {
    top: -450px;
    left: 120px;
    z-index: 4;
    margin: 40px;
  }
  .gallery-container .item-4 {
    top: -60rem;
    left: 12rem;
    z-index: 3;
  }
  .gallery-container .item-5 {
    top: -1400px;
    left: 460px;
    z-index: 6;
    margin: 15px;
  }
}/*# sourceMappingURL=storeGallery.css.map */