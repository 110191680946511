.hero-container {
  background-image: url(../../assets/barber-hero-cli.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 32vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero-container .hero-title {
  font-size: 8vw;
  font-weight: 800;
  align-items: center;
  /** TEXT GRADIENT */
  color: #F8D379;
  background-image: -webkit-radial-gradient(circle, rgb(253, 188, 23) 41%, #D3A73E 67%, #C48F38 87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-container .hero-border {
  background: url(../../assets/barber_border.png);
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 18vh;
  width: 100%;
  height: 142px;
  z-index: 500;
}

@media screen and (min-width: 768px) {
  .hero-container {
    height: 100vh;
  }
  .hero-container .hero-border {
    background: url(../../assets/barber_border.png);
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 90vh;
    width: 100%;
    height: 142px;
    z-index: 500;
  }
}/*# sourceMappingURL=hero.css.map */