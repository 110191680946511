@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

.team-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: #0000;
    margin-top: 15rem;
    // margin-bottom: 6rem;
    padding-top: 2rem;
    display: flex;
    justify-items: center;
    align-items: center;

    .team-border {
        @include tear-border-reverse(90vh);
    }

    .team-border-rev{
        @include tear-border(65%);
    }

    video {
        width: 100%;
        height: auto;
    }

    .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
        z-index: 1; /* Ensure it's above the video but below the content */
    }

    .team-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
        color: #fff;

        .barber-image {
            border-radius: 50%;
            width: 90px; 
            height: 90px;
            object-fit: cover;
            border: 3px solid white;
            cursor: pointer;
        }

        h1 {
            font-family: 'Spectral', serif;;
            margin-top: 1rem;
            font-size: 1rem;
        }
        
        p {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 0.8rem;
            letter-spacing: 0.01em;
            text-align: center;
            color: #ffffff;
            display: block;
        }
    }
}

@media (min-width: 768px) {
    .team-container{
        .team-border {
            @include tear-border-reverse(90%);
        }
        .team-border-rev{
            @include tear-border(90%);
        }
        .team-content {
            .barber-image {
                width: 150px;
                height: 150px;
            }
            
            h1 {
                font-size: 2rem;
            }
        
            p {
                font-size: 1.2rem;
            }
        }
    
    
    }
}