@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

.reviews-container{
    // max-width: 600px;
    // height: 50vh;
    height: auto;
    margin: 5vh 0;
    // padding: 20px;
    border-radius: 8px;
    // position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    // z-index: 1;

    .reviews-title{
        h1{
            font-family: 'spectral-sc', sans-serif;
            font-size: 5rem;
        }

        p{
            @include pre-title;
        }

        h2 {
            @include title;
        }

        h2::after{
            @include decor-after;
        }
    }

    .review {
        opacity: 0;
        animation: fadeInOut 6s infinite;
        margin: 5vh 0;
        // position: absolute;
        width: 100%;

        p {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-style: italic;
            font-size: 21px;
            margin: 0 5rem;
            text-align: center;
        }
    }

    .reviews-border {
        // @include tear-border(200vh);
        // bottom: 120vh;
        // position:sticky;
        // z-index: 1;
        // transform: translate(0, -10vh) rotate(180deg);
        background-color: black;
    }

    .razor{
        display: none;
    }
}



@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    25%, 75% { opacity: 1; }
}


@media screen and (min-width: 765px){
    .reviews-container{
        .reviews-title{
            p{
                font-size: 16px;
            }
        }

        .review {
            p {
                font-size: 32px;
            }
        }
        
        .razor{
            position: absolute;
            right: 10%;
            z-index: 700;
            transform: translate(0%, -20%);
            display: unset;
            padding: 15%;
            background: url(../../assets/barber_razor.png);
            background-position: center 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}