@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.team-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 15rem;
  padding-top: 2rem;
  display: flex;
  justify-items: center;
  align-items: center;
}
.team-container .team-border {
  background: url(../../assets/barber_border.png);
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 90vh;
  width: 100%;
  height: 142px;
  z-index: 500;
  transform: rotate(180deg);
  top: 0;
}
.team-container .team-border-rev {
  background: url(../../assets/barber_border.png);
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 65%;
  width: 100%;
  height: 142px;
  z-index: 500;
}
.team-container video {
  width: 100%;
  height: auto;
}
.team-container .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  z-index: 1; /* Ensure it's above the video but below the content */
}
.team-container .team-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: #fff;
}
.team-container .team-content .barber-image {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  -o-object-fit: cover;
     object-fit: cover;
  border: 3px solid white;
  cursor: pointer;
}
.team-container .team-content h1 {
  font-family: "Spectral", serif;
  margin-top: 1rem;
  font-size: 1rem;
}
.team-container .team-content p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;
  display: block;
}

@media (min-width: 768px) {
  .team-container .team-border {
    background: url(../../assets/barber_border.png);
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 90%;
    width: 100%;
    height: 142px;
    z-index: 500;
    transform: rotate(180deg);
    top: 0;
  }
  .team-container .team-border-rev {
    background: url(../../assets/barber_border.png);
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 90%;
    width: 100%;
    height: 142px;
    z-index: 500;
  }
  .team-container .team-content .barber-image {
    width: 150px;
    height: 150px;
  }
  .team-container .team-content h1 {
    font-size: 2rem;
  }
  .team-container .team-content p {
    font-size: 1.2rem;
  }
}/*# sourceMappingURL=team.css.map */