
@mixin button-style {
    padding: 1rem;
    margin: 1em 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0.1em;
    color: #020303;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid black;
    cursor: pointer;
}

@mixin golden-text{
    color: #F8D379; 
    background-image: -webkit-radial-gradient(circle, rgb(253, 188, 23) 41%, #D3A73E 67%, #C48F38 87%); 
    background-clip: text; 
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
}

@mixin pre-title{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0.5vw;
    color: black;
}

@mixin title{
    font-family: 'spectral', sans-serif;
    word-spacing: 1vw;
    letter-spacing: 0.2vw;;
    font-size: 32px;
}

@mixin title-xl{
    font-size: 5vw;
    font-weight: 700;
    font-family: 'Spectral', serif;
    text-transform: uppercase;
}

@mixin tear-border($tp){
    background: url(../../assets/barber_border.png);
    background-position: center;
    background-size: cover;
    position:absolute;
    top: $tp;
    width: 100%;
    height: 142px;
    z-index: 500;
}

@mixin tear-border-reverse($tpr){
    @include tear-border($tpr);
    transform: rotate(180deg);
    top: 0;
}

@mixin decor-before{
    display: block;
    margin: 0 auto;
    content: ' ';
    background-image: url(../../assets//icons/barber_deco.svg);
    background-repeat: no-repeat;
    background-size: 129px 13px;
    height: 13px;
    width: 129px;
    transform: rotate(180deg);
    margin-bottom: 16px;
}

@mixin decor-after{
    display: block;
    margin: 0 auto;
    content: ' ';
    background-image: url(../../assets//icons/barber_deco.svg);
    background-repeat: no-repeat;
    background-size: 129px 13px;
    height: 13px;
    width: 129px;
    margin-top: 16px;
    margin-bottom: 40px;
}